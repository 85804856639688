import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";

import "remixicon/fonts/remixicon.css";
import "./tailwind.css";
import { ThemeProvider, useTheme } from "~/styles/ThemedProvider";
import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import i18next from "~/config/i18next.server";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { getAppwriteClient } from "~/services/appwrite.server";

export async function loader({ request }: LoaderFunctionArgs) {
  try {
    const [locale, appwriteLocale, { phones }] = await Promise.all([
      i18next.getLocale(request.clone()),
      getAppwriteClient().locale.get(),
      getAppwriteClient().locale.listCountriesPhones(),
    ]);

    return json({ appwriteLocale, locale, phones });
  } catch {
    return json({ locale: "es", phones: [] });
  }
}

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: ["common", "errors"],
};

export const meta: MetaFunction = () => {
  return [
    { title: "Process.io" },
    { name: "description", content: "Optimizá tu vida" },
  ];
};

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
  },
];

export function ThemedLayout({ children }: { children: React.ReactNode }) {
  const [theme] = useTheme();
  const loaderData = useLoaderData<typeof loader>();
  const { i18n } = useTranslation("common");

  return (
    <html
      lang={loaderData?.locale ?? "es"}
      dir={i18n.dir()}
      className={twMerge(theme)}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex min-h-screen w-full flex-col items-stretch tablet:overflow-x-hidden ">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider>
      <ThemedLayout>{children}</ThemedLayout>
    </ThemeProvider>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
